<script lang="ts" setup>
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  notSidesheet: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close'])
</script>

<template>
  <div>
    <teleport to="body">
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
        mode="out-in"
      >
        <div
          v-if="props.show"
          class="fixed inset-0 flex items-center justify-center bg-opacity-80 bg-black z-40"
          @click="$emit('close')"
        />
      </transition>
    </teleport>

    <teleport to="body">
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
        mode="out-in"
      >
        <div
          v-if="props.show"
          class="fixed flex self-center justify-center bg-white rounded-lg z-50 h-fit w-fit" :class="[
            {
              'xl:left-1/2 xl:-translate-x-1/2 bottom-0 w-full xl:w-fit xl:inset-0': !notSidesheet,
              'w-[calc(100vw_-_32px)] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2': notSidesheet,
            },
          ]"
        >
          <slot />
        </div>
      </transition>
    </teleport>
  </div>
</template>
