import { isZipCode, isZipCodePart } from '@/validators/helpers'
import type { NuxtError } from '#app'
import type { ILocation } from '../types/location.d'

interface ILocationSearchResponse {
  items: ILocation[]
  page: number
  pages: number
  size: number
  total: number
}
interface ISearchState {
  data: ComputedRef<ILocationSearchResponse | ILocation>
  pending: ComputedRef<boolean>
  error: ComputedRef<NuxtError | null>
  termIsZipCode: ComputedRef<boolean>
  termIsZipCodePart: ComputedRef<boolean>
}

const path = '/v1/recommendations/district'

export default function (term: Ref<string>): ISearchState {
  const baseURL = useRuntimeConfig().public.api.awsGateway

  const termIsZipCodePart = computed(() => isZipCodePart(term.value))
  const termIsZipCode = computed(() => isZipCode(term.value))

  const fetched = useAsyncData<ILocationSearchResponse | ILocation | null>('location-search', async () => {
    if (term.value.length < 2)
      return null

    if (termIsZipCodePart.value && !termIsZipCode.value)
      return null

    const query = termIsZipCode.value ? { zipcode: term.value } : { city: term.value }

    return await $fetch(path, { baseURL, query })
  }, {
    watch: [term],
    transform: snakeToCamel,
  })

  return {
    termIsZipCode,
    termIsZipCodePart,
    data: computed(() => fetched.data.value ?? { items: [], page: 0, pages: 0, size: 0, total: 0 }),
    pending: computed(() => fetched.pending.value),
    error: computed(() => fetched.error.value),
  }
}
