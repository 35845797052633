<script setup lang="ts">
import type { ILocation } from '@/modules/location/types/location.d'

const props = defineProps({
  city: {
    type: Object as PropType<ILocation>,
    required: true,
  },
  selected: {
    type: Object as PropType<ILocation>,
    default: () => ({}) as ILocation,
  },
  term: {
    type: String,
    required: true,
  },
})

defineEmits(['select'])

const hasSelected = computed<boolean>(() => {
  if (!props.selected.slug)
    return false

  return props.selected.slug === props.city.slug
})
</script>

<template>
  <li
    class="flex items-center px-4 py-4 hover:bg-primary-50/20 group"
    :class="hasSelected ? 'cursor-default' : 'cursor-pointer'"
    @click="$emit('select', city)"
  >
    <Icon name="ic:outline-location-on" class="w-6 h-6 text-primary-50 mr-4" />

    <div class="flex flex-col">
      <span
        :class="hasSelected ? 'font-bold' : ''"
        class="text-base text-primary-10 group-hover:text-primary-50"
        v-html="$filters.turnBold(city.city, term)"
      />

      <span
        :class="hasSelected ? 'font-bold' : ''"
        class="text-sm text-primary-10 group-hover:text-primary-40"
      >
        {{ city.state }}
      </span>
    </div>
  </li>
</template>
