<script setup lang="ts">
import { TestABGroups } from '@/modules/core/enums'
import type { ICategory, ICategoryItem, IMenuLink } from '@/modules/home/types/home'

defineEmits(['close'])

const baseURL = useRuntimeConfig().public.api.awsGateway
const { data } = useFetch<ICategory>('/v1/marketplace/categories', {
  baseURL,
})
const citySlug = useLocationParam().value

const showCategories = ref(false)
const links = computed<IMenuLink[]>(() => {
  const baseLinks: IMenuLink[] = [
    {
      text: 'Quem somos',
      link: '/quem-somos',
      target: '_self',
      external: false,
    },
    {
      text: 'Ajuda',
      link: '/faq',
      target: '_self',
      external: false,
    },
    {
      text: 'Blog',
      link: 'https://blog.saudetrevo.com.br/',
      target: '_blank',
      external: true,
    },
  ]

  if (showCategories.value) {
    baseLinks.splice(1, 0, {
      text: 'Exames',
      link: '',
      items: data.value?.items.map((item: ICategoryItem) => ({
        text: item.name,
        link: `/${citySlug}/categorias/${item.slug}`,
        target: '_self',
        external: false,
      })),
    })
  }

  return baseLinks
})

onNuxtReady(() => {
  if (abTest() === TestABGroups.SHOW_CATEGORIES)
    showCategories.value = true
})
</script>

<template>
  <ul class="flex flex-col xl:items-center gap-6 xl:flex-row xl:gap-10 w-full">
    <li
      v-for="(item, index) in links"
      :key="`link-${index}`"
    >
      <NuxtLink
        v-if="item.link"
        class="py-2 text-base font-normal leading-6 hover:xl:border-b border-primary-50 text-primary-50 px-4 flex items-center"
        active-class="font-bold"
        :external="item.external"
        :to="item.link"
        :target="item.target"
        no-prefetch
        @click="$emit('close')"
      >
        {{ item.text }}
      </NuxtLink>

      <THeaderSubMenu v-else :title="item.text" :items="item.items" />
    </li>

    <slot name="more-options" />
  </ul>
</template>
