<template>
  <div
    v-if="useGeolocationPopupActiveState().value"
    class="absolute w-[313px] bg-white shadow-lg rounded-lg top-[80px] xl:top-[90px] right-[16px] xl:-ml-[70px] pt-3 pb-2 px-4"
  >
    <span class="font-semibold text-primary-20 leading-5 text-sm">
      Confira a distância para o seu endereço
    </span>
    <p class="text-primary-20 text-sm leading-5 mt-1">
      Inclua seu CEP ou localização para verificar a distância precisa na sua
      busca.
    </p>
    <div class="flex items-center mt-3">
      <Button class="mr-5" @click="useLocationModalOpen">
        Incluir CEP
      </Button>

      <span class="text-primary-50 font-semibold leading-5 text-sm cursor-pointer" @click="useGeolocationPopupCloseInSession">
        Mais tarde
      </span>
    </div>
  </div>
</template>
