<script setup lang="ts">
const props = defineProps({
  pending: {
    type: Boolean,
    default: false,
  },
  term: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['clearable'])

enum StatesIcon {
  CLOSE = 'mdi:close',
  MAGNIFY = 'mdi:magnify',
  PENDING = 'mingcute:loading-fill',
}

const icon = computed(() => {
  if (props.pending)
    return StatesIcon.PENDING

  return props.term.length ? StatesIcon.CLOSE : StatesIcon.MAGNIFY
})

function clearable() {
  if (props.term && !props.pending)
    emit('clearable')
}
</script>

<template>
  <Icon
    :name="icon"
    class="group-focus-within:text-primary-50 "
    :class="{
      'animate-spin text-primary-50': icon === StatesIcon.PENDING,
      'text-neutral-400': icon === StatesIcon.MAGNIFY,
      'cursor-pointer': icon === StatesIcon.CLOSE && term.length,
    }"
    height="24"
    width="24"
    @click="clearable"
  />
</template>
