<script setup lang="ts">
defineProps({
  placeholder: {
    type: String,
    default: 'Buscar por CEP ou Cidade',
  },
})

const emit = defineEmits(['searched', 'termUpdated', 'close'])

const term = ref<string>('')
const dTerm = refDebounced(term, 300, { maxWait: 600 })
const error = ref<boolean>(false)

const { data, pending, termIsZipCodePart, termIsZipCode } = useLocationSearchByTerm(dTerm)

function handleInput() {
  error.value = false

  emit('termUpdated', term)
}

watch(data, () => {
  if (!termIsZipCode.value) {
    emit('searched', data.value.items)

    return
  }
  if (!data.value?.postalCode) {
    error.value = true

    return
  }

  useLocationParam().value = data.value?.postalCode

  emit('close')
})
</script>

<template>
  <div>
    <div
      class="bg-white border border-neutral-60 focus:border-primary-50 rounded-lg transition-all w-full"
      :class="[
        {
          'border-error-50 text-error-50': error,
          'border-neutral-60': !error,
        },
      ]"
    >
      <div class="flex items-center justify-between px-4">
        <input
          v-model.trim="term"
          v-maska
          :data-maska="termIsZipCodePart ? '#####-###' : ''"
          type="text"
          :placeholder="placeholder"
          class="w-[calc(100%_-_40px)] py-3 text-xs xl:text-base font-normal placeholder:text-primary-10 outline-none"
          @input="handleInput"
        >

        <SearchIconRight
          :pending="pending"
          :term="term"
          @clearable="term = ''"
        />
      </div>
    </div>

    <small v-if="error" class="text-error-50 text-xs leading-4">
      Nenhum resultado encontrado. Tente pesquisar outro item ou em uma área
      diferente.
    </small>
  </div>
</template>
