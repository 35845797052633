<script setup lang="ts">
import { LocationType } from '@/modules/location/types/enums'

const emit = defineEmits(['close'])

function geolocation() {
  navigator.geolocation.getCurrentPosition(async ({ coords }) => {
    const { latitude: lat, longitude: lng } = coords

    useLocationParam().value = `${lat.toString()},${lng.toString()}`
  })

  emit('close')
}
</script>

<template>
  <div
    v-if="useLocationParamObject().value.type !== LocationType.GEOLOCATION"
    class="mt-2 rounded-lg cursor-pointer bg-neutral-variant-96 px-5 py-4 w-full"
    @click="geolocation"
  >
    <Icon name="mdi:target" class="w-6 h-6 text-primary-50" />
    <span class="ml-4 text-primary-50 text-base">
      Usar minha localização
    </span>
  </div>
</template>
