<script setup lang="ts">
import { LocationType } from '../types/enums'

defineEmits(['showModal', 'closeModal'])

const { data, error } = useLocation()

const label = computed(() => {
  if (!data.value)
    return ''

  if (useLocationParamObject().value.type === LocationType.CITY)
    return data.value.showName

  if (useLocationParamObject().value.type === LocationType.GEOLOCATION)
    return 'Sua localização'

  if (useLocationParamObject().value.type === LocationType.ZIP_CODE)
    return data.value.postalCode

  showError('Tipo de localização inválido')
  return null as never
})

const oldLocation = ref(useLocationParam().value)
watch(data, () => {
  if (!error.value)
    oldLocation.value = useLocationParam().value

  else
    useLocationParam().value = oldLocation.value
})
</script>

<template>
  <div class="flex items-center">
    <LocationButton
      :label="label"
      @show-modal="useLocationModalOpen"
    />

    <LocationModal
      :label="label"
    />

    <LocationPopupGeolocation />
  </div>
</template>
