<template>
  <header class="h-16 mb-16">
    <div class="fixed top-0 bg-primary-99 shadow-sm z-30 w-full h-16">
      <div class="py-2 container mx-auto flex items-center justify-between px-4 sm:px-0">
        <div class="flex items-center">
          <h1>
            <CoreLogoTrevo id="logo-header" />
          </h1>

          <THeaderNavLinks class="hidden xl:flex ml-14" />
        </div>

        <THeaderActions />
      </div>
    </div>
  </header>
</template>
