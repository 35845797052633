<script setup lang="ts">
const { cart } = useCart()
</script>

<template>
  <button
    aria-label="Link para acessar orçamentos"
    type="button"
    class="flex-row-reverse relative text-primary-50 cursor-pointer disabled:cursor-not-allowed disabled:text-primary-20 disabled:opacity-[0.16] p-1 flex items-center gap-2"
    :disabled="!cart || !cart.cartDetails?.length"
    @click="navigateTo('/checkout')"
  >
    <span
      v-if="cart?.cartDetails?.length"
      class="xl:absolute left-0 top-0 flex items-center justify-center text-sm font-semibold text-white bg-error-50 min-w-[16px] min-h-[16px] w-4 h-4 rounded-full"
    >
      {{ cart?.cartDetails?.length }}
    </span>

    <IconProceduresResume class="hidden xl:block" />

    <span class="inline xl:hidden ml-3">Orçamentos</span>
  </button>
</template>
