<script setup lang="ts">
import type { IMenuLinkItem } from '@/modules/home/types/home'

defineProps({
  title: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<IMenuLinkItem[]>,
    default: () => [],
  },
})

const opened = ref(false)
</script>

<template>
  <div class="py-2 group pl-4">
    <div class="text-primary-50 flex justify-between items-center cursor-pointer gap-3" @click="opened = !opened">
      <span class="font-normal leading-6">
        {{ title }}
      </span>

      <Icon
        name="mdi:chevron-down"
        :class="{ 'rotate-180 xl:rotate-0 group-hover:xl:rotate-180': opened, 'rotate-0 group-hover:xl:rotate-180': !opened }"
        class="w-5 h-5 duration-300"
      />
    </div>

    <ul
      :class="{ hidden: !opened, flex: opened }"
      class="w-full xl:hidden group-hover:xl:flex flex-col xl:bg-neutral-99 mt-2 xl:absolute xl:w-[300px] rounded xl:shadow divide-y divide-neutral-60"
    >
      <NuxtLink
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        class="font-normal hover:xl:bg-primary-99 leading-6 text-base text-primary-20 py-4 pl-12 cursor-pointer first:hover:xl:rounded last:hover:xl:rounded"
        :to="item.link"
        :target="item.target"
        :external="item.external"
      >
        {{ item.text }}
      </NuxtLink>
    </ul>
  </div>
</template>
